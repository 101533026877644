<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Bonita Layout',
  colors: {
    fields: {
      palette: true,
      picker: true,
    },
    background: {
      palette: true,
      picker: true,
    },
    foreground: {
      palette: false,
      picker: true,
    },
  },
  colorPalette: {
    '#20432C': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#7A8F70': {
      fields: true,
      background: true,
      foreground: false,
    },
    '#E4E2D4': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#DF6548': {
      fields: true,
      background: true,
      foreground: false,
    },
    '#FA9CA2': {
      fields: true,
      background: true,
      foreground: false,
    },
    '#E7AD16': {
      fields: true,
      background: true,
      foreground: false,
    },
  },
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

// if (!isVoixTemplatizer)
// setupHeader('a27adfd1-a837-4704-97c4-5b910df8afc6')

const {
  resorts,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
  footerTermsMenu,
} = await getCommonLayoutData(isVoixTemplatizer, ['brands'])

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#20432C' })
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-bonita-inverse bonita-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />

        <BonitaNavbar
          v-if="!isVoixTemplatizer"
          :current-resort="currentResort?.data"
          :resorts="resorts?.data"
        />

        <slot />

        <BonitaFooter
          v-if="!isVoixTemplatizer"
          :current-resort="currentResort?.data"
          :footer-company-menu="footerCompanyMenu?.data"
          :footer-resources-menu="footerResourcesMenu?.data"
          :footer-terms-menu="footerTermsMenu?.data"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/sass/bonita.scss';
</style>
