<script setup lang="ts">
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

const bookingWidgetStore = useBookingWidgetStore()
function toggleBookingModal() {
  bookingWidgetStore.toggleBookingModal('nav')
}

const showBookingWidget = computed(() => bookingWidgetStore.showBookingModal)

const placeholderMenu = [
  'Rooms',
  'Activities',
  'Dining',
  'Spa',
  'Weddings',
  'Special Offers',

]
</script>

<template>
  <div class="bg-bonita-primary text-bonita-inverse py-12 text-xs">
    <div
      v-if="showBookingWidget" class="px-6 py-4 w-full relative"
    >
      <FlightBookingForm class="lg:container" />
      <button class="p-4 absolute z-100 top-1 lg:top-0 -right-1 m-4 lg:m-0 pointer-events-auto" @click="toggleBookingModal">
        <Icon name="heroicons:x-mark-20-solid" class="text-gray lg:text-bonita-primary w-8 h-8" />
      </button>
    </div>
    <div class="max-w-[1200px] mx-auto grid lg:grid-cols-12 lg:gap-16">
      <div class="col-span-4 flex space-x-2">
        <div class="border-r border-bonita-inverse pl-8 pr-2 flex items-center">
          <span>Logo</span>
        </div>
        <div class="flex flex-col justify-center items-start space-y-2">
          <button class="" @click="toggleBookingModal">
            {{ $t("book-now") }}
          </button>

          <a href="tel:+15555555555" class="block text-bonita-inverse">+1 (555) 555-5555</a>
          <div>Call us. It's toll free</div>
        </div>
      </div>

      <ul class="col-start-6 col-span-3 space-y-2">
        <li v-for="menuItem, key in placeholderMenu" :key="key">
          <a href="#" class="text-xs uppercase tracking-widest underline underline-offset-4">{{ menuItem }}</a>
        </li>
      </ul>
      <ul class="col-start-9 col-span-3 space-y-2">
        <li v-for="menuItem, key in placeholderMenu" :key="key">
          <a href="#" class="text-xs uppercase tracking-widest underline underline-offset-4">{{ menuItem }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
